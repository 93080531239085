#lab_start_dialog.alert, #survey-notification {
  display: none;

  form {
    padding-top: $alert-padding;
  }

  .opt-out {
    padding-left: $alert-padding;
  }
}
