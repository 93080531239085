// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '_variables.scss';
@import 'mixins/extra';

@import '_imported.scss';

@import 'inc/fonts';
@import "inc/buttons";
@import "inc/panels";

@import "mixins/flexbox";
@import "inc/deployment";
@import "inc/navbar";
@import "inc/alert";
@import "inc/tables";
@import "inc/tabs";
@import "inc/form";
@import "inc/course_toc";
@import "inc/icons";
@import "inc/course_catalog";
@import "inc/progress_map";
@import "inc/early_access";
@import "inc/print";
@import "inc/notes";
@import '_card.scss';



html {
  overflow: hidden;
  height: 100%;
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 10px;
  margin-inline-end: 0;
}

body {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  line-height: 22.576px;
  font-weight: 400;
  color: $text-color;
  background-color: $color-white;
  font-display: fallback;

  height: 100%;
  overflow: auto;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.col-border-row {
  margin-left: -30px;
  margin-right: -30px;
}

.col-border {
  .col-border {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.row-eq-height {
  @include flexbox;
}

.band {
  padding: 60px 0 80px;
}

.band-alt {
  background: #f0f0f0;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #0088ce !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 141, 211, 0.8) !important;
}

.subheading,
.card-heading {
  font-size: 18px;
  line-height: 25.398px;
  font-weight: 600;
}

.card-body {
  padding: 15px !important;
}

.card-white {
  color: #252527;
  background: $color-white;
}

.login-form {
  .lostPasswordLink {
    margin-left: 15px;
  }
}

.form-control {
  border-radius: 0;
}

.informaltable,
.table-contents {
  th,
  td {
    padding: 5px;
  }
}

.split-container {
  padding: 15px;
  height: 100%;
}

.nav-tabs .nav-link.active {
  border-top: 4px solid red !important;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    max-width: 600px !important;
  }
}

h5.view-section-title {
  background-color: #f8f8f8;
  line-height: 2.25em;
}

h3.view-page-title {
  background-color: #f8f8f8;
  line-height: 2.5em;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}

div.view-page-title {
  background-color: #f8f8f8;
  line-height: 2.5em;
  padding: 15px;
  margin-bottom: 0;
}

.due-date {
  color: red;
}

.secondary-title {
  margin-top: $gap;
}

.error-message {
  color: red;
}

.asterisk {
  color: #ee0000;
  margin-left: 2px;
}

.the-kaltura-player {
  aspect-ratio: 16 / 9;
}

.warning-message {
  color: #6b9fd6;
}
