.early-access-catalog {
  .panel-heading {
    background-color: $early-access-primary-color;
  }
}

.early-access-label {
  @extend .badge;
  background-color: $early-access-primary-color;
  color: #fff;

  &.inverted {
    color: $early-access-primary-color;
    background-color: #fff;
  }
}

.early-access-course {
  .course-title-container {
    background-color: $early-access-primary-color;
    color: #fff;
    margin-top: -20px;
    margin-bottom: 40px;
    position: relative;

    .course-title {
      margin-bottom: 0;
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 20px;

      transition: font-size 200ms ease;

      .early-access-label {
        font-size: 0.5em;
      }
    }

    &.sticky {
      z-index: 999;
      margin-top: 0;

      .course-title {
        font-size: 1.8em;
        @media (max-width: $screen-xs-max) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  #survey-notification,
  #tab_course_favorites,
  #tab_course_settings,
  #tab_course_help,
  .titlepage-favorite {
    display: none !important;
  }
}
