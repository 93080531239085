// Selectors

// Color code as per new style guide
$btn-primary-color: #0066cc;
$link-active: #004080;

// Red Hat Brand color palette
$color-primary: #cc0000;
$brand-primary: $color-primary;
$primary:$color-primary;
$color-secondary: #004153;
$secondary:$color-secondary;
$color-secondary-light: #007a87;
$color-secondary-dark: #004254;
$color-accent-blue: #0088ce;
$color-tertiary: #51a5b1;
$color-light-purple: #4e76b0;
$color-white: #ffffff;
$color-green: #479b2e;
$color-course: #50a3b0;
$color-class: $color-secondary-light;
$color-expert-seminar: #5073b0;
$color-learning-path: #5f526d;
$color-exam: #004153;
$color-prelim-exam: #2274a5;
$color-card-border: #50A3B0;
$color-card-border-blue: #397ed4;
$color-disabled: #6A6E73;

$info:black;
$modal-content-border-color:#dee2e6;
// Red Hat Web Standard
$color-ws-cancel-dark: #6a6e73;
$color-ws-cancel-light: #d2d2d2;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: #252527;
$gray: #646464;
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: #d2d3d5;
$gray-lightest: #eee;

// Bootstrap overrides
$text-color: #4c4c4c;
$link-color: #0066cc;
$link-color-hover: #004080;
$color-side-bar-menu: black;
$btn-icon-sidenavigation: $color-side-bar-menu;
$progress-bg:#f5f5f5;

// Buttons
$btn-secondary: $color-secondary-light;
$btn-secondary-border: darken($color-secondary-light, 5%);

// Fonts
$font-path: '../fonts/';
$img-path: '../../img/';
$icon-font-path: $font-path;

$font-family-sans-serif: 'RedHatDisplay', 'Overpass', 'Open Sans', Helvetica, sans-serif;

$base_selector: '.role_lms_container';
$page_selector: '.rht_rhls_page';
$seminar_selector: '.rht_rhls_seminar';

// Colors
$color-white: #fff;
$color-primary: #c00;
$color-primary-hover: #b30000;

$dark-text: #646464;
$early-access-primary-color: #3f9c35;

// Favorites
$favorite-icon-color: #ffbf04;
$favorite-icon-font-size: 24px;

// Notes
$note-default-color: #9fcbd1;
$note-references-color: #c9e5f2;
$note-important-color: #eb8b38;
$note-warning-color: #d71a3a;
$note-comparison-color: $note-default-color;

// Forms
$form-padding: 15px;

$border-color: #090808;
$background-color: #222;

// Migrated from Bootstrap 3
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;
$alert-padding: 15px !default;
$panel-body-padding: 15px !default;
$padding-large-vertical: 10px !default;
$panel-default-heading-bg: #f5f5f5 !default;
$grid-gutter-width:         30px !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;


// Inverse navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #222 !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        #333 !default;

// Screen
// Extra small screen / phone
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

$input-bg: white !default;

$gap: 15px;
