@mixin icon($icon) {
	background: url('#{$img-path}/icons/#{$icon}.svg') no-repeat center;
	display: inline-block;
	color: black;
	width: 100%;
	height: 100%;
}

.icon-modality-course,
.icon-modality-partner {
	@include icon('modality-course');
}

.icon-modality-ilt,
.icon-modality-os {
	@include icon('modality-ilt');
}

.icon-modality-vt {
	@include icon('modality-vt');
}

.icon-modality-vc,
.icon-modality-video_classroom {
	@include icon('modality-vc');
}

.icon-modality-exam {
	@include icon('checklist');
}

.icon-quiz {
	@include icon('quiz_icon');
}

.icon-code-quiz {
	@include icon('code_icon');
}

.icon-arrow-up {
	@include icon('web-icon-caret-thin-up');
}

.icon-arrow-down {
	@include icon('web-icon-caret-thin-down');
}

.icon-lab {
	@include icon('lab_icon');
}

.icon-book {
	@include icon('book_icon');
}

.icon-learning-path {
	@include icon('learning-path');
}

.icon-question-mark {
	@include icon('question-mark');
}

.icon-close-black {
	@include icon('close-black');
}

.icon-download {
	@include icon('download-icon');
}

.icon-video {
	@include icon('video-icon');
}

.icon-image {
	@include icon('image-icon');
}

.icon-file {
	@include icon('file-icon');
}

.icon-external-link-new {
	@include icon('external-link-new');
}

.icon-play-video {
	@include icon('play-video');
}

.external-icon{
	margin-right: 0.25rem;
}
