.chapter, .section {
  h4 {
    font-size: 21px;
  }

  h5 {
    font-size: 18px;
  }
}

.section {
  padding: 5px 0;

  img {
    max-width: 100%;
  }

  code {
    color: inherit;
    background-color: transparent;
    padding: 0;
    word-wrap: break-word;
  }

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.428571429;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;

    code {
      font-size: inherit;
      white-space: pre-wrap;
      border-radius: 0;
      font-weight: bold;
    }
  }

  em {
    display: inline-block;
  }

  .guilabel,
  .guimenu,
  .guisubmenu,
  .guimenuitem,
  .guibutton {
    font-weight: 600;
  }

  ul.itemizedlist.compact > li > p {
    margin-bottom: 5px;
  }

  .mediaobject {
    padding: 20px 0;
  }

  .screen span > img {
    width: 15px;
    height: 15px;
    margin-top: -10px;
  }

  .calloutlist span > img {
    width: 22px;
    height: 22px;
  }

  .informaltable {
    margin: 0 0 15px;
  }

  .title {
    margin-top: 0;
    max-width: 95%;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 20px;

  .pf-v5-c-button {
    padding: var(--pf-v5-global--spacer--form-element) var(--pf-v5-global--spacer--md);
  }
}

ul.itemizedlist > li.listitem,
.orderedlist > li.listitem {
  margin-left: 20px;
}

.interactive_buttons {
  display: flex;
  justify-content: space-between;

  button {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.interactive_buttons button:last-child {
  margin-left: auto;
  margin-right: 0;
}

.toc > dl > dd {
  display: none;
}

.graphic-iframe {
  display: flex;
  justify-content: center;
}
