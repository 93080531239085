#{$base_selector} {
  .tab-content {
    padding-top: 10px;
  }

  .inline-tab {
    &:hover {
      border-color: transparent;
    }

    &:hover, &:focus {
      background-color: transparent;
    }
  }
}
