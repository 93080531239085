#{$base_selector} {
  .form-padded {
    padding-top: $form-padding;
  }

  fieldset {
    width: 100%;
    border: 1px solid $nav-tabs-border-color;
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: $form-padding;
  }

  legend {
    border: none;
    margin: 0;
    padding: .5em;
    width: auto;
    background: white;
    font-size: 1em;
    font-weight: 600;
  }
}
