@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-right: ($gutter / 2);
	padding-left: ($gutter / 2);

	@media (min-width: $screen-sm-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin label-variant($color) {
	background-color: $color;

	&[href] {
		&:hover,
		&:focus {
			background-color: darken($color, 10%);
		}
	}
}
