.panel-secondary {
  border-color: $color-secondary;
}

.panel-secondary > .panel-heading {
  color: white;
  background-color: #005C65;
  border-color: $color-secondary;
}

.panel-secondary-dark {
  border-color: #004254;
}

.panel-secondary-dark > .panel-heading {
  color: white;
  background-color: #004254;
  border-color: $color-secondary;
}
