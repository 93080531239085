.offering-card-container {
  color: $gray-base;
  cursor: pointer;

  &.card {
    border-radius: 0;
    padding: 0;
    border-left-width: 5px;
    min-height: 170px;
    display: inline-flex;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .card-body {
    display: flex;
  }

  .card-footer {
    padding: 0;
  }

  &.exam {
    .course-icon,
    .progress-bar {
      background-color: $color-exam;
    }
  }

  &.prelim-exam {
    .course-icon,
    .progress-bar {
      background-color: $color-prelim-exam;
    }
  }

  &.expert_seminar {
    .course-icon,
    .progress-bar {
      background-color: $color-expert-seminar;
    }
  }
}

@media (max-width:845px) {
  .offering-card-container {
    &.card {
      min-width: 300px;
      width: 100%;
    }
  }
}

@media (min-width:846px) and (max-width:1140px) {
  .offering-card-container {
    &.card {
      min-width: 300px;
      width: 300px;
    }
  }
}

@media (min-width:1141px) and (max-width:1440px) {
  .offering-card-container {
    &.card {
      min-width: 300px;
      width: 320px;
    }
  }
}

@media (min-width:1441px) {
  .offering-card-container {
    &.card {
      min-width: 300px;
      width: 400px;
    }
  }
}
