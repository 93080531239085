// Imported from Bootstrap 3

.navbar-inverse {
	background-color: $navbar-inverse-bg;
	border-color: $navbar-inverse-border;

	.navbar-brand {
		color: $navbar-inverse-brand-color;
		&:hover,
		&:focus {
			color: $navbar-inverse-brand-hover-color;
			background-color: $navbar-inverse-brand-hover-bg;
		}
	}

	.navbar-text {
		color: $navbar-inverse-color;
	}

	.navbar-nav {
		> li > a {
			color: $navbar-inverse-link-color;

			&:hover,
			&:focus {
				color: $navbar-inverse-link-hover-color;
				background-color: $navbar-inverse-link-hover-bg;
			}
		}
		> .active > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-inverse-link-active-color;
				background-color: $navbar-inverse-link-active-bg;
			}
		}
		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-inverse-link-disabled-color;
				background-color: $navbar-inverse-link-disabled-bg;
			}
		}

		// Dropdowns
		> .open > a {
			&,
			&:hover,
			&:focus {
				color: $navbar-inverse-link-active-color;
				background-color: $navbar-inverse-link-active-bg;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
			// Dropdowns get custom display
			.open .dropdown-menu {
				> .dropdown-header {
					border-color: $navbar-inverse-border;
				}
				.divider {
					background-color: $navbar-inverse-border;
				}
				> li > a {
					color: $navbar-inverse-link-color;
					&:hover,
					&:focus {
						color: $navbar-inverse-link-hover-color;
						background-color: $navbar-inverse-link-hover-bg;
					}
				}
				> .active > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-inverse-link-active-color;
						background-color: $navbar-inverse-link-active-bg;
					}
				}
				> .disabled > a {
					&,
					&:hover,
					&:focus {
						color: $navbar-inverse-link-disabled-color;
						background-color: $navbar-inverse-link-disabled-bg;
					}
				}
			}
		}
	}

	// Darken the responsive nav toggle
	.navbar-toggle {
		border-color: $navbar-inverse-toggle-border-color;
		&:hover,
		&:focus {
			background-color: $navbar-inverse-toggle-hover-bg;
		}
		.icon-bar {
			background-color: $navbar-inverse-toggle-icon-bar-bg;
		}
	}

	.navbar-collapse,
	.navbar-form {
		border-color: darken($navbar-inverse-bg, 7%);
	}

	.navbar-link {
		color: $navbar-inverse-link-color;
		&:hover {
			color: $navbar-inverse-link-hover-color;
		}
	}

	.btn-link {
		color: $navbar-inverse-link-color;
		&:hover,
		&:focus {
			color: $navbar-inverse-link-hover-color;
		}
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: $navbar-inverse-link-disabled-color;
			}
		}
	}
}


@media (min-width: $grid-float-breakpoint) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
  margin-right: -$navbar-padding-horizontal;

    ~ .navbar-right {
      margin-right: 0;
    }
  }
}

$label-default-bg:            $gray-light !default;

.label-default {
  @include label-variant($label-default-bg);
}
