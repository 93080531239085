.global-navbar-wrapper {
  @extend .navbar;
  @extend .navbar-inverse;

  margin-top: -20px;
  border-radius: 0;

  @media not all and (max-width: $screen-xs-max) {
    height: 51px;
  }

  .container {
    position: relative;
    padding: 0;
  }

  .navbar-toggle {
    float: none;
  }

  .left-nav, .right-nav {
    @extend .nav;
    @extend .navbar-nav;

    margin: 0;

    & > li {
      & > a {
        color: $color-white;
      }
    }
  }

  .left-nav {
    & > li {
      .new-label {
        @extend .badge;
        background-color: #fff;
        color: $text-color;
      }

      &.active {
        background-color: $color-white;

        .new-label {
          background-color: $color-secondary;
          color: #fff;
        }

        & > a, & > a:hover {
          color: $text-color;
          background-color: transparent;
        }

        @media not all and (max-width: $screen-xs-max) {
          &:after {
            content: "";
            display: block;
            margin: -4px 0;
            padding: 0;
            line-height: 1px;
            border-bottom: 4px solid $color-primary;
          }
        }
      }

      &:hover:not(.active) {
        background-color: $list-group-hover-bg;

        .new-label {
          background-color: $color-secondary;
          color: #fff;
        }

        & > a {
          color: $text-color;
          background-color: transparent;
        }

        @media not all and (max-width: $screen-xs-max) {
          &:after {
            content: "";
            display: block;
            margin: -4px 0;
            padding: 0;
            line-height: 1px;
            border-bottom: 4px solid black;
          }
        }
      }
    }
  }

  .right-nav {
    @extend .navbar-right;

    .dropdown {
      height: 50px;

      .dropdown-menu {
        padding: 10px;
        margin-right: 0;
        background-color: #f0f0f0;

        button {
          line-height: 1;
        }
      }
    }

    #search-dropdown {
      background: transparent;
      border: none;

      &:focus {
        outline: 0;
      }
    }

    #search-dropdown-menu {
      max-height: calc(100vh - 250px);
      overflow-y: hidden;

      @media (max-width: $screen-xs-max) {
        width: 100% !important;
        border-radius: 0;
        margin-top: 10px;
        max-height: none;
      }

      .search-within-course-wrapper {
        margin-top: 20px;
      }

      .search-content {
        width: 100%;
        margin-top: 20px;

        padding: 0 15px;

        .search-query {
          font-style: italic;
        }

        .search-results {
          max-height: calc(100vh - 450px);
          overflow-y: scroll;

          .search-result {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
