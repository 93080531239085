@media print {
  @page {
    margin: 9mm;
  }

  .masthead {
    display: none;
  }

  .footer-main {
    display: none;
  }

  .container {
    width: 100% !important;
  }

  .dataTables_length,
  .dataTables_filter,
  .dataTables_info,
  .dataTables_paginate {
    display: none;
  }

  #course_catalog_table_filter_area {
    display: none;
  }

  .rht-content-panel {
    border: none;
  }

  .dataTable {
    width: 100% !important;
    max-width: 100% !important;

    th, td {
      width: auto !important;
    }

    th:after {
      display: none !important;
    }
  }
}
