.btn {
  border-radius: 3;
  font-size: 14px;
  line-height: 19.754px;
  padding: 9px 40px;
  height: auto;
  font-weight: bold;
  transition-duration: 0.4s;
  transition-property: background;
}

.btn-primary {
  @include button-variant($color-primary, $color-primary, $color-primary);
}

.btn-secondary {
  @include button-variant($color-white, $btn-secondary, $btn-secondary);

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &:active:hover,
  &:active:focus,
  &.active,
  .open > &.dropdown-toggle {
    color: white;
    background-color: darken($btn-secondary, 10%); //10% default
    border-color: darken($btn-secondary, 10%); // 12% default
    &:focus,
    &.focus,
    &:hover {
      color: white;
      background-color: darken($btn-secondary, 10%); //10% default
      border-color: darken($btn-secondary, 10%); // 12% default
    }
  }
}

// Styling for toggle switches
.toggle-switch {
  text-align: right;

  &:not(:last-child) {
    padding-bottom: 5px;
  }

  & > span {
    margin-right: $form-group-margin-bottom;
  }

  input[type="checkbox"] {
    display: none;
  }

  .toggle.btn {
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    line-height: 1.42857143;

    .btn {
      padding: $padding-base-vertical $padding-base-horizontal;
      font-size: 14px;
      font-weight: 400;
      border: none;
      border-radius: 4px;
      line-height: 1.42857143;
    }

    .toggle-on {
      padding-right: $padding-base-horizontal * 2;
    }

    .toggle-off {
      padding-left: $padding-base-horizontal * 2;
    }
  }
}

.course-translations-btn,
.course-versions-btn,
.certificate-of-attendance-btn  {
  text-transform: none;
  font-size: 100%;
  font-weight: normal;
}

#course_toolbar {
  margin-bottom: 20px;
}

.btn {
  padding: 9px 20px;
}

.btn-primary-new {
  color: #ffffff !important;
  background-color: #0066CC !important;
  border-color: #0066CC !important;
}
.btn-secondary-new {
  color: #0066CC !important;
  background-color: #ffffff !important;
  border-color: #0066CC !important;
}
.btn-tertiary-new {
  color: #151515 !important;
  background-color: #ffffff !important;
  border-color: #151515 !important;
}
