#course_catalog_table, #early_access_catalog_table {
  width: 100% !important;

  .course-overview {
    cursor: pointer;

    &:not(:first-child) {
      border-top: 1px solid $gray-lighter;
    }
  }

  .course-summary-toggle {
    width: $panel-body-padding * 2 !important;
  }

  .course-summary-toggle-header {
    width: $panel-body-padding * 2 !important;
  }

  th, td {
    border-top: none;
    width: 100%;
  }

  .lang-tag {
    @extend .label;
    @extend .label-default;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .modality-icon {
    width: 32px;
    height: 32px;
  }

  .course-summary {
    padding-left: $panel-body-padding * 2;

    .course-summary-content {
      @include make-row;
      margin: 0 $panel-body-padding;
    }

    .description {
      @include make-sm-column(12);
      font-size: 0.9em;
    }

    .modality {
      @include make-row;
      margin-bottom: $padding-large-vertical;

      .name {
        @include make-sm-column(4);
        display: table;

        .modality-text {
          display: table-cell;
          vertical-align: middle;
          width: 100%;
        }
      }

      .action {
        @include make-sm-column(3);
      }

      .languages {
        @include make-sm-column(4);
      }

      .progress-indicator {
        @include make-sm-column(1);
        position: relative;

        strong {
          position: absolute;
          top: 18px;
          left: 15px;
          width: 50px;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
}

#course_catalog_table_filter_area, #early_access_catalog_table_filter_area {
  background-color: $panel-default-heading-bg;
  margin: 0 (-$panel-body-padding) 20px (-$panel-body-padding);
  padding: $table-cell-padding $panel-body-padding;

  label {
    margin-right: 5px;
  }

  .course_catalog_table_filter_dropdown, .early_access_catalog_table_filter_dropdown {
    &:not(:last-child) {
      margin-right: 5px;
    }

    button {
      padding: 9px;
      text-transform: initial;
    }
  }
}
