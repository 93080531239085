@import '../../../styles/variables';

.announcement-slider {
    
    padding: 1em;
    margin-bottom: 0;

    .border-blue {
        color: #004368;
        border: 1px solid #004368;
        border-radius: .25rem;
    }

    .border-red {
        color: #820000;
        border: 1px solid #820000;
        border-radius: .25rem;
    }
    
    .announcement{
        display: flex;
        padding: 1em;
        height: 100px;
        .announcement-content {
            .title{
                font-weight: bold;
            }
        }
        

        .info-icon {
            vertical-align: top;
            padding-left: 2em;
            padding-right: 1em;
        }
    
        .title {
            line-height: 1.5em;
        }
    }

    .announcement-url {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-right: 2em;
        
      }

    @media (max-width: $screen-xs-max) { 
        .announcement {
            height: 260px;
        }
     }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
        .announcement {
            height: 200px;
        }
    }
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        .announcement {
            height: 140px;
        }
    }
    @media (min-width: $screen-lg-min) {
        .announcement {
            height: 110px;
        }
    }

    .actions {
        text-align: right;
    }

    .carousel-control {
        width: 2em;
    }

    .carousel-indicators li {
        background-color: #999;
        background-color: rgba(70, 70, 70, 0.25);
    }
    
    .carousel-indicators .active {
        background-color: #444;
    }

    .carousel-control-next {
        width: 60px;
    }

    .carousel-control-prev {
        width: 60px;
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
       
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
}