.dataTables_wrapper {
  .dataTables_filter {
    text-align: right;
  }

  .dataTables_length,
  .dataTables_filter {
    @media (max-width: $screen-xs-max) {
      width: 100%;
      text-align: center;

      .label {
        width: 100%;
      }
    }
  }

  .dataTables_paginate {
    text-align: right;
  }

  table {
    &.dataTable {
      width: 100% !important;

      thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
          cursor: pointer;
          position: relative;

          &:after {
            position: absolute;
            bottom: 11px;
            right: 8px;
            display: block;
            font-family: 'Glyphicons Halflings';
            font-size: 12px;
            opacity: 0.5;
          }
        }

        .sorting:after {
          opacity: 0.2;
          content: "\e150";
        }

        .sorting_asc:after {
          content: "\e155";
        }

        .sorting_desc:after {
          content: "\e156";
        }

        tr > {
          th, td {
            padding-right: 30px;
          }
        }
      }
    }
  }
}
